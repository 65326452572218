import * as React from 'react';
import { HomePageContainer } from './homePageTemplateStyle';
import PromoBanner from '../../components/promoBanner/promoBanner';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import MigrationPanel from '../../components/migrationDetail/migrationPanel';
import BluewavePledge from '../../components/bluewavePledgePane/bluewavePledge';
import DeliveringMore from '../../components/deliverMore/deliveringMore';
import LocationMapPanel from '../../components/locationMap/locationMapPanel';
import CustomerService from '../../components/customerService/customerService';
import HealthAndSafety from '../../components/healthAndSafety/healthAndSafety';
import AccountTemplate from '../../components/navigationBar/accountTemplate/accountTemplate';
import { Row, Col, Container } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import constants from '../../utils/utils';
import * as Styled from './homePageTemplateStyle';
import TrackDelivery from '../../components/trackDelivery/trackDelivery';
import SubFooter from '../../components/subFooter/subFooter';
import iconAccountOpenHome from '../../images/icon-account-open.png';
import iconAccountOpenBusiness from '../../images/icon-account-open-purple.png';
import orderFuelOnlineHome from '../../images/icon-order-fuel.png';
import orderFuelOnlineBusiness from '../../images/icon-order-fuel-commercial.png';
import AccountCredit from '../../components/accountCredit/accountCredit';
import { Helmet } from 'react-helmet';

const templateAccount = [
  {
    accountTypeColor: '#00483A',
    anchorBGColor: '#6E2A8D',
    anchorHoverColor: '#642a7e',
    isLeft: true,
  },
  {
    accountTypeColor: '#00483A',
    anchorBGColor: '#FFC116',
    anchorHoverColor: '#e8a300',
  },
];
const HomePage = () => {
  const pageData = useStaticQuery(graphql`
    query myHomePageQuery {
      allContentfulColumbiaFuel(
        filter: {
          identifier: { type: { eq: "homePage" } }
          node_locale: { eq: "en-US" }
        }
      ) {
        edges {
          node {
            title
            slug
            node_locale
            metadata
            sections {
              ... on ContentfulHomeContainers {
                id
                title
                type
                homecontainers {
                  type
                  title
                  label
                  textWithLink1 {
                    ... on ContentfulTextWithPath {
                      id
                      path
                      type
                      text
                    }
                  }
                  backgroundImages {
                    file {
                      url
                      fileName
                    }
                  }
                  textWithLink2 {
                    ... on ContentfulTextWithPath {
                      id
                      type
                      text
                      path
                    }
                  }
                  textWithLink3 {
                    ... on ContentfulTextWithPath {
                      id
                      path
                      type
                      text
                    }
                  }
                }
              }
              ... on ContentfulImageWithPath {
                id
                title
                type
                image {
                  file {
                    fileName
                    url
                  }
                }
                path
              }
              ... on ContentfulListOfImageWithPath {
                id
                title
                type
                listOfImagesWithPath {
                  path
                  title
                  type
                  richText {
                    raw
                  }
                  image {
                    title
                    file {
                      fileName
                      url
                    }
                  }
                }
              }
              ... on ContentfulContentList {
                id
                title
                type
                list {
                  header
                  title
                  type
                  contentDetails {
                    raw
                  }
                }
              }
              ... on ContentfulImageContainer {
                id
                title
                type
                image {
                  title
                  file {
                    fileName
                    url
                  }
                }
                headerWithText {
                  title
                  type
                  headerText
                }
                textWithPaths {
                  ... on ContentfulTextWithPath {
                    id
                    type
                    text
                    path
                  }
                }
              }
              ... on ContentfulTextsWithMultipleImages {
                id
                type
                title
                headerWithText {
                  title
                  type
                  headerText
                  text {
                    text
                  }
                }
                textWithLinks {
                  text
                  type
                  path
                }
                images {
                  file {
                    fileName
                    url
                  }
                  title
                }
              }
              ... on ContentfulTextWithMultipleImagesReferences {
                id
                title
                type
                headerText
                imagereferences {
                  ... on ContentfulMutipleImagesWithPath {
                    id
                    type
                    title
                    path
                    images {
                      file {
                        fileName
                        url
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const sections =
    pageData?.allContentfulColumbiaFuel?.edges[0]?.node?.sections;
  const accountTemplateData = sections?.filter(
    (section) => section?.type === constants.HOME.HOME_CONTAINERS
  )[0];
  return (
    <>
      <Helmet>
        {pageData?.allContentfulColumbiaFuel?.edges[0]?.node?.metadata && (
          <meta
            name="description"
            content={
              pageData?.allContentfulColumbiaFuel?.edges[0]?.node?.metadata
            }
          ></meta>
        )}
      </Helmet>
      <HomePageContainer>
        <DefaultHeaderMenu />
        <BootstrapNavbar />
        <Styled.ImageContainer className="p-0">
          <Row className="g-0">
            <Col xs={12} md>
              <AccountTemplate
                {...accountTemplateData?.homecontainers?.filter(
                  (section) =>
                    section.type === constants.HOME.HOME_RESIDENTIAL_SECTION
                )[0]}
                {...templateAccount[0]}
                iconAccountOpen={iconAccountOpenHome}
                orderFuelOnline={orderFuelOnlineHome}
              />
            </Col>
            <Col xs={12} md>
              <AccountTemplate
                {...accountTemplateData?.homecontainers?.filter(
                  (section) =>
                    section.type === constants.HOME.HOME_COMMERCIAL_SECTION
                )[0]}
                {...templateAccount[1]}
                iconAccountOpen={iconAccountOpenBusiness}
                orderFuelOnline={orderFuelOnlineBusiness}
              />
            </Col>
          </Row>
        </Styled.ImageContainer>
        <PromoBanner
          section={
            sections?.filter(
              (section) => section.type === constants.HOME.HOME_PROMO
            )[0]
          }
        />
        <TrackDelivery
          section={
            sections?.filter(
              (section) =>
                section.type === constants.HOME.HOME_TRACK_YOUR_DELIVERY
            )[0]
          }
        />
        <MigrationPanel
          section={
            sections?.filter(
              (section) =>
                section.type === constants.HOME.HOME_RESIDENTIAL_CUSTOMERS
            )[0]
          }
        />
        {/* <AccountCredit
          section={
            sections?.filter(
              (section) => section.type === constants.HOME.HOME_ACCOUNT_CREDIT
            )[0]
          }
        /> */}
        <Container fluid className="p-0">
          <DeliveringMore
            homeCards={
              sections?.filter(
                (section) => section.type === constants.HOME.HOME_CARDS
              )[0]
            }
            homeDeliveryProducts={
              sections?.filter(
                (section) =>
                  section.type === constants.HOME.HOME_DELIVERY_PRODUCTS
              )[0]
            }
          />
        </Container>
        <LocationMapPanel
          section={
            sections?.filter(
              (section) => section.type === constants.HOME.HOME_MAP_CONTAINER
            )[0]
          }
        />
        <CustomerService
          section={
            sections?.filter(
              (section) => section.type === constants.HOME.HOME_CUSTOMER_CARE
            )[0]
          }
        />
        <HealthAndSafety
          section={
            sections?.filter(
              (section) => section.type === constants.HOME.HOME_HSC_SECTION
            )[0]
          }
        />
        <BluewavePledge
          section={
            sections?.filter(
              (section) => section.type === constants.HOME.HOME_PLEDGE_PROGRAM
            )[0]
          }
        />
        <Footer showProducts={true} />
        <SubFooter />
      </HomePageContainer>
    </>
  );
};

export default HomePage;
