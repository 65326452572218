import * as React from 'react';
import * as Styled from './accountCreditStyles';
import { Row, Col } from 'react-bootstrap';
import isExternalUrl, {
  isExternalUrlHref,
  addTrailingSlash,
} from '../../utils';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../hooks/useContentfulImages';

const AccountCredit = ({ section }) => {
  const imageData = section.list.filter((item) => {
    return item.type === 'accountCreditImage';
  })[0];
  const textData = section.list.filter((item) => {
    return item.type === 'accountCreditText';
  })[0];
  const heatingOilData = section.list.filter((item) => {
    return item.type === 'learnHeatingOilTextwithButton';
  })[0];
  const propaneData = section.list.filter((item) => {
    return item.type === 'learnPropaneTextwithButton';
  })[0];
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return (
          <Styled.Dollar src={asset.node.file.url} alt={asset.node.title} />
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.Header2>{children}</Styled.Header2>
      ),

      [INLINES.HYPERLINK]: (node, children) =>
        isExternalUrl(node?.data?.uri) ? (
          <Styled.LinkAttribute
            target="_blank"
            href={
              isExternalUrlHref(node?.data?.uri)
                ? node?.data?.uri
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                  )
            }
          >
            {children}
          </Styled.LinkAttribute>
        ) : (
          <Styled.LinkAttributeInternal
            to={
              isExternalUrlHref(node?.data?.uri)
                ? node?.data?.uri
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                  )
            }
          >
            {children}
          </Styled.LinkAttributeInternal>
        ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <Styled.ActionCreditContainer>
      <Styled.ActionCreditInnerContainer fluid className="p-0">
        <Row className="g-0">
          <Col xs={12} md={4} lg={2} xl={2}>
            {documentToReactComponents(
              JSON.parse(imageData?.contentDetails?.raw),
              optionsMainStyle
            )}
          </Col>
          <Col xs={12} md={8} lg={10} xl="auto">
            <Row className="g-0">
              <Styled.MiddleColumn xs={12} md={12} lg={6} xl="auto">
                <Styled.Text>
                  <Styled.Header2>{textData.header}</Styled.Header2>
                  <Styled.LeftPara>
                    {documentToReactComponents(
                      JSON.parse(textData?.contentDetails?.raw)
                    )}
                  </Styled.LeftPara>
                </Styled.Text>
              </Styled.MiddleColumn>
              <Styled.RightCol xs={12} md={6} lg={3} xl="auto">
                {documentToReactComponents(
                  JSON.parse(heatingOilData?.contentDetails?.raw),
                  optionsMainStyle
                )}
              </Styled.RightCol>
              <Styled.RightCol xs={12} md={6} lg={3} xl="auto">
                {documentToReactComponents(
                  JSON.parse(propaneData?.contentDetails?.raw),
                  optionsMainStyle
                )}
              </Styled.RightCol>
            </Row>
          </Col>
        </Row>
      </Styled.ActionCreditInnerContainer>
    </Styled.ActionCreditContainer>
  );
};

export default AccountCredit;
