import * as React from 'react';
import * as Styled from './accountTemplateStyles';
import { Col } from 'react-bootstrap';
import arrowResiLarge from '../../../images/arrow-large-residential.png';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils/index';

const AccountTemplate = (props) => {
  return (
    <Styled.AccountContainer
      $backgroundImage={props?.backgroundImages[0]?.file?.url}
      $backgroundImageSmallDevice={props?.backgroundImages[1]?.file.url}
      $isLeft={props?.isLeft}
    >
      <Styled.WrapperInnerDiv>
        Your
        {isExternalUrl(props?.textWithLink1?.path) ? (
          <Styled.AnchorAccountType
            target="_blank"
            href={
              isExternalUrlHref(props?.textWithLink1?.path)
                ? props?.textWithLink1?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + props?.textWithLink1?.path
                  )
            }
            $color={props?.accountTypeColor}
          >
            {props?.textWithLink1?.text}
            <Styled.ImageStyleArrow src={arrowResiLarge} />
          </Styled.AnchorAccountType>
        ) : (
          <Styled.AnchorAccountTypeInternal
            to={
              isExternalUrlHref(props?.textWithLink1?.path)
                ? props?.textWithLink1?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + props?.textWithLink1?.path
                  )
            }
            $color={props?.accountTypeColor}
          >
            {props?.textWithLink1?.text}
            <Styled.ImageStyleArrow src={arrowResiLarge} />
          </Styled.AnchorAccountTypeInternal>
        )}
      </Styled.WrapperInnerDiv>

      <Styled.TabRow className="g-0">
        {props?.isLeft && <Col md={1}></Col>}
        <Styled.WrapperTabDiv
          xs={7}
          md={6}
          $isBorder={true}
          $color={props?.anchorBGColor}
          $hoverColor={props?.anchorHoverColor}
        >
          <Styled.AnchorTagButton
            href={
              isExternalUrlHref(props?.textWithLink2?.path)
                ? props?.textWithLink2?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + props?.textWithLink2?.path
                  )
            }
            $isLeft={props?.isLeft}
            target={
              isExternalUrl(props?.textWithLink2?.path) ? '_blank' : '_self'
            }
          >
            <Styled.TextRow className="g-0">
              <Col md>{props.textWithLink2?.text}</Col>
              <Styled.WrapperImageCol xl={2}>
                <Styled.ImageStyleLogo
                  $height="19px"
                  src={props.iconAccountOpen}
                  alt={props?.textWithLink2?.text}
                />
              </Styled.WrapperImageCol>
            </Styled.TextRow>
          </Styled.AnchorTagButton>
        </Styled.WrapperTabDiv>

        <Styled.WrapperTabDiv
          xs={5}
          lg={5}
          $isBorder={false}
          $color={props?.anchorBGColor}
          $hoverColor={props?.anchorHoverColor}
        >
          <Styled.AnchorTagButton
            href={
              isExternalUrlHref(props?.textWithLink3?.path)
                ? props?.textWithLink3?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + props?.textWithLink3?.path
                  )
            }
            $isLeft={props?.isLeft}
            target={
              isExternalUrl(props?.textWithLink3?.path) ? '_blank' : '_self'
            }
          >
            <Styled.TextRow className="g-0">
              <Col md>{props?.textWithLink3?.text}</Col>
              <Styled.WrapperImageCol xl={2}>
                <Styled.ImageStyleLogo
                  $height="19px"
                  src={props.orderFuelOnline}
                  alt={props?.textWithLink3?.text}
                />
              </Styled.WrapperImageCol>
            </Styled.TextRow>
          </Styled.AnchorTagButton>
        </Styled.WrapperTabDiv>
        {!props.isLeft && <Col md={1}></Col>}
      </Styled.TabRow>
    </Styled.AccountContainer>
  );
};

export default AccountTemplate;
