import styled from 'styled-components';
import { Row, Container, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

export const ActionCreditContainer = styled.div`
  background-color: #fff;
`;

export const ActionCreditInnerContainer = styled(Container)`
    display: grid;
    width: 1200px !important;
    height: auto;
    padding: 45px 0 60px 40px !important;
    margin: 0 auto;
    border-top: none !important;
    @media (max-width: 1200px) {
      display: block;
      width: 960px !important;
    }
    @media (max-width: 991px) {
        gap: 10px;
        width: 728px !important;
      border-top: 1px solid #666 !important;

    }
    @media (max-width: 767px) {
        /* display: block; */
        width: 100% !important;
        padding: 35px !important;
        
    }
`;
export const Dollar = styled.img`
    max-width: auto;
    height: 140px;
    border: 0;
    vertical-align: top;
    @media (max-width: 760px) {
      width: 110px;
      height: auto;
    }
`;
export const MiddleColumn = styled(Col)`
  padding: 0px !important;
    
`;
export const Text = styled.div`
    display: inline-block;
    width: 500px;
    padding: 20px;
    vertical-align: middle;
    text-align: center;
    color: #6e2d8a;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 40px;
    margin-right: 30px;
    @media (max-width: 767px) {
        width: 80% !important;
        font-size: 28px;
        line-height: 34px;
    }
    @media (max-width: 992px){
      justify-self: center;
      margin-right: 0px;
      padding: 15px;
      width: 375px;
      font-size: 22px;
    }
    @media (max-width: 1200px) {
      width: 400px;
      font-size: 25px;
      line-height: 30px;
    }
`
export const Header2 = styled.h2`
    font-family: 'Figtree-Bold', arial, sans-serif;
    margin: 0px !important;
    font-weight: bold !important;
    @media (max-width: 1200px) {
      font-size: 25px;
      line-height: 30px;
    }
    @media (max-width: 992px){
      font-size: 22px;
    }
    @media (max-width: 760px){
        font-size: 28px !important;
        line-height: 34px;
    }
`;
export const LeftPara = styled.p`
    font-size: 14px;
    line-height: 22px;
    text-transform: none;
    color: #6e2d8a;
    margin-top: 10px;
    margin-bottom: 0px !important;
    @media (max-width: 1200px){
      font-size: 11px;
    }
    @media (max-width: 992px) {
        font-size: 10px;
    }
    @media (max-width: 767px) {
        line-height: 15px;
        padding: 0;
        margin-bottom: 0 !important;
        font-size: 10px;
    }
`;

export const RightCol = styled(Col)`
    align-self: center;
    padding-left: 0 !important;
    p{
      @media (max-width: 767px){
        margin:0px;
    }
    }
`;
export const LinkAttribute = styled.a`
    display: inline-block;
    font-weight: bold;
    font-family: 'Figtree-Bold', arial, sans-serif;
    text-decoration: none;
    letter-spacing: 0.02em;
    font-size: 1rem;
    width: 175px;
    height: 105px;
    padding: 20px;
    vertical-align: middle;
    text-align: center;
    background: #ffc116;
    color: #6e2d8a;
    margin-right: 40px;
    transition: .15s;
  :hover {
    color: #fff;
    background: #6e2c8b;
  }
  transition: all 0.25s ease-out;
  @media (max-width: 1200px) {
    width: 150px;
    font-size: 14px;
  }
  @media (max-width: 992px) {
    width: 150px;
    font-size: 1rem;
    line-height: 17px;
    padding: 15px;
    margin-right: 20px;
    height: 80px;
  }
  @media (max-width: 767px) {
    width: 100%;
    font-size: 1rem;
    line-height: 19px;
    height: auto;
    margin-left: 0px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 15px;
  }
`;
export const LinkAttributeInternal = styled(Link)`
    display: inline-block;
    font-weight: bold;
    font-family: 'Figtree-Bold', arial, sans-serif;
    text-decoration: none;
    letter-spacing: 0.02em;
    font-size: 1rem;
    width: 175px;
    height: 105px;
    padding: 20px;
    vertical-align: middle;
    text-align: center;
    background: #ffc116;
    color: #6e2d8a;
    margin-right: 40px;
    transition: .15s;
  :hover {
    color: #ffc116;
    background: #6e2c8b;
  }
  transition: all 0.25s ease-out;
  @media (max-width: 1200px) {
    width: 150px;
    font-size: 14px;
  }
  @media (max-width: 992px) {
    width: 150px;
    font-size: 1rem;
    line-height: 17px;
    padding: 15px;
    margin-right: 20px;
    height: 80px;
  }
  @media (max-width: 767px) {
    width: 100%;
    font-size: 1rem;
    line-height: 19px;
    height: auto;
    margin-left: 0px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 15px;
  }
`;